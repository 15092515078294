/**
 * Created by Marijan on 8.11.2015..
 */
$( function() {

        var arr = ['country','county','city','hoods','neighborhood','categories','sale-types','seller' ];
    $.each( arr, function( i, val ) {
        $( "." + val ).selectize({
            valueField: 'id',
            labelField: 'title',
            searchField: ['title'],
            sortField: [{field: 'title', direction: 'asc'}],
            preload: true,
            maxItems: 1,
            options: [],
            render: {
                option: function(item, escape) {
                    return '<div>'+escape(item.title)+'</div>';
                }
            },
            load: function(query, callback) {
                $.ajax({
                    url: 'http://'+$(location).attr('hostname')+'/api/'+ val,
                    type: 'GET',
                    dataType: 'json',
                    error: function() {
                        callback();
                    },
                    success: function(res) {
                        callback(res);
                    }
                });
            },
            create: false
        });
    });
});
/**
 * Created by Marijan on 09.03.2016..
 */
$( function() {
    if($('#map').length){
            var lat = $('#map').data('lng'),
                lng = $('#map').data('lat'),
                title = $('#map').data('title'),
                address = 'temp'//$('#map').data('adress');
                
            var map = new GMaps({
                el: '#map',
                lat: lat,
                lng: lng,
                zoom:15,
                scrollwheel: false,
            });
            map.addMarker({
                lat: lat,
                lng: lng,
                title: title,
                infoWindow: {
                    content: '<h3 class="text-primary">'+ title+'</h3><p>Adresa:<br/><b>'+ address +'</b></p>'
                }
            });
        }
 
 var submitRequest = function(e) {
        var form = $(this);
       var method = form.find('input[name="_method"]').val() || 'POST';
        $.ajax({
            type: method,
            url: form.attr('action'),
            data: form.serialize(),
            success: function(data) {
                if(data.success==true && data.title ){
                    swal(data.title, data.message, "success");
                  
                }
                else if(data.success==false && data.message ){
                    swal(data.title, data.message, "error");
                }
            },
            error:function(data){
                if(data.success==false && data.message ){
                    swal(data.title, data.message, "error");
                }

            },
            
        });

        e.preventDefault();
    };


 $('form[data-remote]').on('submit', submitRequest);
 
});
/**
 * Created by Marijan on 8.11.2015..
 */
$( function() {
    if( $('nav a[href^="/' + location.pathname.split("/")[1] + '"]').parent('li').hasClass('child') )
        $('nav a[href^="/' + location.pathname.split("/")[1] + '"]').parent('li').parent('ul').parent('li').addClass('active');
    else
        $('nav a[href^="/' + location.pathname.split("/")[1] + '"]').parent('li').addClass('active');
    

});/*End function() wrapper*/